footer {
  border-top: 1px solid #fff8de;
  color: #575757;
  font-size: 14px;
  // font-family: "metric";
  font-weight: 400;
  padding: 30px 0px 25px;
  a {
    display: inline-block;
    &:first-child {
      margin-right: 15px;
    }
    color: #575757;
  }
  @media screen and (max-width:768px) {
    text-align: center;
    line-height: 2;
    .desk_right{
      text-align: center;
    }
  }
}
.desk_right {
  text-align: right;
}
