input[type="number"] {
  -moz-appearance: textfield;
}
.claim_head {
  background-color: #fffdf6;
  span {
    @media screen and (max-width: 768px) {
      // display: none;
    }
  }
}
.claim_step_cont {
  list-style: none;
  margin: 0 -15px;
  float: right;
  position: relative;
  @media screen and (max-width: 768px) {
    float: none;
    text-align: left;
    padding: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 7px;
  }
  @media screen and (max-width: 475px) {
    padding-left: 56px;
  }
}
.claim_step_indicator {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #494f7b;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 10px;
  z-index: 1;
  position: relative;
  &.active {
    background-color: #494f7b;
    &:after {
      background-color: #494f7b;
    }
  }
  &:after {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
  }
  &:before {
    content: "";
    width: 35px;
    height: 2px;
    display: block;
    position: relative;
    top: 6.5px;
    left: 7.5px;
    background-color: #494f7b;
    z-index: -1;
  }
  &:last-child {
    &:before {
      display: none;
    }
  }
}

.claim_byo_info {
  // font-family: "metric", sans-serif;
  padding: 30px 0px;
  color: #575757;
  @media screen and (max-width: 768px) {
    padding: 0px 0px 30px;
  }
  h4 {
    font-weight: 600;
    font-size: 20px;
    @media screen and (max-width: 768px) {
      margin-top: 15px;
    }
  }
}
.claim_name {
  font-size: 32px;
  font-weight: 600;
}
.claim_addr {
  margin: 0px;
  max-width: 280px;
  margin-bottom: 7px;
}
.clain_email {
  margin: 0px;
  font-weight: 500;
}
.phone_self {
  color: #494f7b;
  font-weight: 600;
  letter-spacing: 0.2px;
  font-size: 18px;
  img {
    width: 35px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}
.twilio_box {
  text-align: center;

  padding: 45px 30px;
  border: 1px solid #ffe9b4;
  background-color: #fffdf6;
}
.twilio_instruction {
  font-weight: 700;
  // font-family: "metric", sans-serif;
  font-size: 22px;
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  color: #3c4167;
  margin-bottom: 25px;
  b{
    font-weight: 900;
    color: #dbc07a;
  }
}
.twillio_receive_call {
  width: 220px;
  margin-left: auto;
  margin-right: auto;
  // font-family: "metric", sans-serif;
}
.number_calling {
  background-color: #efdfb8;
  padding: 15px 15px;
  font-weight: 600;
  color: #3c4167;
  font-size: 18px;
  margin-top: 18px;

  img {
    height: 30px;
    margin-right: 5px;
  }
}
.progress_indicator {
  display: block;
  position: relative;
  background-color: #fbecc6;
  height: 7px;
  margin-top: 15px;
  border-radius: 25px;
  overflow: hidden;
  span {
    background-color: #c3982a;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    border-radius: 25px;
  }
}
.time_left {
  text-align: right;
  display: block;
  color: #a8a08b;
  font-size: 13px;
  margin-top: 3px;
}
.button_positioning {
  // font-family: "metric", sans-serif;
  text-align: right;
  margin: 40px 0px 80px;
  .primary_button{
    margin-bottom: 10px;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}
.link_button {
  color: #3c4167;
  font-weight: 600;
  border: 0px;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  margin-right: 15px;
  font-size: 17px;
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
    font-size: 19px;
  }
}
.primary_button {
  background-color: #3c4167;
  font-weight: 600;
  font-size: 19px;
  color: #fff;
  border: 0px;
  padding: 12px 25px;
  min-width: 200px;
  border-radius: 80px;
}

.form__group {
  margin: 20px 0px;
}
.form_label {
  color: #575757;
  font-size: 18px;
}
.file_wrap {
  position: relative;
  input {
    width: 2px;
    height: 1px;
    position: absolute;
    left: 15px;
    top: 13px;
    opacity: 0.001;
    outline: none;
    &:focus {
      outline: none;
    }
  }
  // font-family: "metric", sans-serif;
  color: #c3982a;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 0.3px;
  img {
    height: 30px;
  }
}

.success_submit_box {
  border: 1px solid #d4c49d;
  background-color: #fffdf6;
  text-align: center;
  padding: 55px;
  @media screen and (max-width:768px) {
    padding: 35px;
  }
  max-width: 500px;
  margin-top: 45px;
  margin-bottom: 75px;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 70px;
    height: 70px;
    margin-bottom: 30px;
  }
  h2 {
    color: #494f7b;
  }
  p {
    // font-family: "metric", sans-serif;
    color: #707070;
    font-size: 17px;
    span{
      color: rgb(5, 5, 5);
      font-weight: 900
    }
    a{
      display: inline-block;
      text-decoration: underline;
    }
    b{
      font-weight: 600;
      color: #3c4167;
    }
  }
}

.custom__menu{
  margin-top: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  box-shadow: 0 2px 18px 0 rgba(0, 4, 63, 0.1) !important;
}
.custom__option{
  cursor: pointer !important;
}
.custom__control--is-focused{
  border-color: #dbc07a !important;
  box-shadow: none !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.DateInput_input {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
}
#update_profile_form{
  input{
    height: 46px;
  }
  .react-phone-number-input input{
    height: 38px !important;
  }
}