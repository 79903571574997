.static_pages {
    padding: 45px 0px 25px;
  p {
    // font-family: "metric", sans-serif;
    font-size: 18px;
  }
}
.page_title {
  text-align: center;
  margin-bottom: 25px;
  @media screen and (max-width:768px) {
      font-size: 30px;
      margin-bottom: 15px;
  }
}
