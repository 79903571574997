.auth_container {
  min-height: calc(100vh - 289px);
}
.login_header {
  background-color: #fffdf6;
}
.heading_with_back {
  color: #494f7b;
  padding: 15px 0px;
  position: relative;
  padding-left: 70px;
  @media screen and (max-width:480px) {
      padding-left: 0px;
  }
}
.back_button {
  position: absolute;
  left: 0px;
  top: 14px;
  cursor: pointer;
  img {
    width: 40px;
  }
  @media screen and (max-width:480px) {
      position: static;
    //   display: block;
      margin-bottom: 10px;
  }
}

.auth_body {
  form.login_form {
    padding-left: 70px;
    max-width: 410px;
    @media screen and (max-width:480px) {
        padding-left: 0px;
        max-width: 100%;
    }
  }
}
.form_group {
  margin: 30px 0px;
  label {
    display: block;
    width: 100%;
    margin-bottom: 7px;
    color: #575757;
  }
  input {
    display: block;
    width: 100%;
    border: 1px solid #d7be7f;
    background-color: #fffdf6;
    outline: none;
    padding: 5px 10px;
    // font-family: "metric", sans-serif;
  }
}
.auth_body input {
  display: block;
  width: 100%;
  border: 1px solid #d7be7f;
  background-color: #fffdf6;
  outline: none;
  padding: 10px 10px;
  // font-family: "metric", sans-serif;
}
.login_button {
  display: block;
  width: 100%;
  background-color: #3c4167;
  color: #fff;
  // font-family: "metric", sans-serif;
  font-weight: 500;
  font-size: 19px;
  border: 0px;
  border-radius: 60px;
  padding: 8px 0px;
  outline: none;
  transition: all ease 0.1s;
  &:hover{
    background-color: #333757;
  }
}
.ask_account_exists {
  text-align: center;
  color: #3c4167;
  font-weight: 500;
  font-size: 15px;
  padding: 11px 0px;
  @media screen and (max-width:480px){
      margin-top: 25px;
      font-size: 19px;
  }
  a {
    color: #cab053;
    // font-weight: 700;
    margin-left: 5px;
    position: relative;
    top: -0.2px;
  }
}
.signup_form {
  padding-left: 70px;
  max-width: 650px;
  @media screen and (max-width:480px){
      padding-left: 0px;
      max-width: 100%;
  }
}

.auth_body {
  .col-md-6,.col-12 {
    margin: 17px 0px;
  }
}
.rc-time-picker-panel-input{
  text-align: center !important;
}
.login_claim_message{
  font-size: 18px;
  font-weight: 500;
  color: red;
  letter-spacing: 0.2px;
  margin-top: 27px;
  // font-family: "metric", sans-serif;
}
.logout_btn{
  color: #c3982a;
  background-color: transparent;
  padding: 0px;
  border: 0px;
  // font-family: 'metric',sans-serif;
  font-size: 21px;
  font-weight: 700;
  float: right;
  margin-top: 15px;
  &:hover{
    color: #b8a168; 
  }
  img{
    height: 19px;
    vertical-align: baseline;
    margin-left: 9px;
    position: relative;
    top: 2px;
  }
  @media screen and (max-width:767px) {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.has-error,.with-errors{
  color : red;
}

// custom phone number input
.react-phone-number-input{
  border: 1px solid #d7be7f;
  background-color: #fffdf6;
  height: 46px;
  input{
    border: 0px !important;
    padding: 0px;
  }
  .react-phone-number-input__icon-image{
    vertical-align: super;
  }
  .react-phone-number-input__row{
    padding: 2.5px 8px;
  }
  .react-phone-number-input__input{
    height: 38px;
  }
}
.forgot_pass_link{
  text-align: right;
  font-size: 13px;
  padding-top: 7px;
}