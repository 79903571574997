@font-face {
  font-family: "mercury";
  src: url("/fonts/Mercury_Text_G4_Bold.eot");
  src: url("/fonts/Mercury_Text_G4_Bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Mercury_Text_G4_Bold.woff2") format("woff2"), url("/fonts/Mercury_Text_G4_Bold.woff") format("woff"),
    url("/fonts/Mercury_Text_G4_Bold.ttf") format("truetype"),
    url("/fonts/Mercury_Text_G4_Bold.svg#ciclefina") format("svg");
  font-weight: 600;
}
@font-face {
  font-family: "mercury";
  src: url("/fonts/Mercury_Text_G4_Semibold.eot");
  src: url("/fonts/Mercury_Text_G4_Semibold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Mercury_Text_G4_Semibold.woff2") format("woff2"),
    url("/fonts/Mercury_Text_G4_Semibold.woff") format("woff"),
    url("/fonts/Mercury_Text_G4_Semibold.ttf") format("truetype"),
    url("/fonts/Mercury_Text_G4_Semibold.svg#ciclefina") format("svg");
  font-weight: 500;
}
@font-face {
  font-family: "metric";
  src: url("/fonts/Metric-Bold.eot");
  src: url("/fonts/Metric-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/Metric-Bold.woff") format("woff"),
    url("/fonts/Metric-Bold.otf") format("truetype"), url("/fonts/Metric-Bold.svg#ciclefina") format("svg");
  font-weight: 700;
}
@font-face {
  font-family: "metric";
  src: url("/fonts/MetricWeb-Semibold.eot");
  src: url("/fonts/MetricWeb-Semibold.eot?#iefix") format("embedded-opentype"), url("/fonts/MetricWeb-Semibold.woff") format("woff"),
    url("/fonts/MetricWeb-Semibold.woff2") format("woff2"), url("/fonts/Metric-Bold.svg#ciclefina") format("svg");
  font-weight: 600;
}
@font-face {
  font-family: "metric";
  src: url("/fonts/Metric-Medium.eot");
  src: url("/fonts/Metric-Medium.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Metric-Medium.woff") format("woff"), url("/fonts/Metric-Medium.ttf") format("truetype"),
    url("/fonts/Metric-Medium.svg#ciclefina") format("svg");
  font-weight: 500;
}
@font-face {
  font-family: "metric";
  src: url("/fonts/MetricWeb-Regular.eot");
  src: url("/fonts/MetricWeb-Regular.eot?#iefix") format("embedded-opentype"),
    url("/fonts/MetricWeb-Regular.woff2") format("woff2"), url("/fonts/MetricWeb-Regular.woff") format("woff"),url("/fonts/Metric.ttf") format("truetype");
  font-weight: 400;
}

html,body{
  font-family: 'Merriweather', Georgia, "Times New Roman", Times, serif;
}
