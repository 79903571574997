input{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
.homepage {
  background-color: #fffdf6;
  // text-align: center;
  padding: 20px 0px 15px;
  @media screen and (max-width: 768px) {
    padding: 15px 0px 15px;
  }
  .wrap_right {
    text-align: right;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
  h2 {
    color: #3c4167;
    font-weight: 700;
    font-size: 22px;
    span {
      // font-weight: 900;
    }
  }
  h3 {
    color: #3c4167;
    font-size: 21px;
  }
  .col-12 {
    @media screen and (max-width: 767px) {
      &:first-child {
        border-bottom: 1px solid #fbefd1;
      }
    }
  }
  .wrap_welcome {
    text-align: left;
    display: inline-block;
  
    @media screen and (max-width: 767px) {
      text-align: center;
      display: block;
      padding: 15px 0px;
    }
  }
}
.sign_in_btn {
  // font-family: "metric", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #c3982a;
  font-weight: 700;
  padding: 0px;
  margin: 0px;
  border: 0px;
  background-color: transparent;
  font-size: 19px;
  line-height: normal;
  height: 21px;
  letter-spacing: 0.2px;
  &:hover {
    // border-bottom: 2px solid #3c4167;
    text-decoration: none;
    color: #bea35e;
  }
  & + span {
    opacity: 0.2;
    display: inline-block;
    padding: 0px 5px;
  }
}
.manage_account_link {
  // font-family: "metric", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #c3982a;
  font-size: 20px;
  font-weight: 700;
  &:hover {
    text-decoration: underline;
    color: #c3982a;
  }
  img {
    height: 22px;
    margin-left: 5px;
  }
}
.new_byo_link {
  display: inline-block;
  border: 1px solid #eee;
  width: 225px;
  text-align: center;
  padding: 10px 0px;
  font-size: 17px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.2px;
  border-radius: 90px;
  background-color: #3c4167;
  outline: none;
  transition: all ease 0.3s;
  &:hover {
    background-color: #d7be7f;
    color: #fff;
    text-decoration: none;
  }
}
.create_byo_link {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.byo_address{
  color: #767676;
}