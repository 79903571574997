header {
  height: 500px;
  height: calc(100vh - 190px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/images/homebg.jpg);
  background-color: #000;
  position: relative;
  z-index: 1;
  transition: all ease 1s;
  @media screen and (max-width: 768px) {
    height: 350px;
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    opacity: 0.6;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.46), rgba(0, 0, 0, 0.78));
    z-index: 0;
  }
}
.site_logo {
  width: 310px;
  margin-bottom: 40px;
  position: relative;
  top: -20px;
  @media screen and (max-width: 768px) {
    max-width: 90%;
  }
}
.search_container {
  // background-color: #eee;
  position: relative;
  display: flex;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 10px 2px 106px -3px rgba(0, 0, 0, 0.55);
  border-radius: 70px;
  input {
    &::-webkit-input-placeholder {
      color: #c3c3c3;
    }
    &::-moz-placeholder {
      color: #c3c3c3;
    }
    &:-ms-input-placeholder {
      color: #c3c3c3;
    }
    &:-moz-placeholder {
      color: #c3c3c3;
    }
  }
}
.padding_align {
  padding: 14px 15px;
}
.zip_input {
  // font-family: "metric", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  padding-left: 45px;
  width: 133px;
  border: 0px;
  height: 50px;
  border-right: 1px solid #e1e1e1;
  border-top-left-radius: 70px;
  border-bottom-left-radius: 70px;
  font-size: 15px;
  letter-spacing: 0.4px;
  outline: none;
  background-image: url(/images/location.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 20px center;
  background-position-x: 20px;
  background-position-y: center;
  @media screen and (max-width: 450px) {
    width: 115px;
    font-size: 14px;
  }
}
.byo_name_input {
  // font-family: "metric", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  border: 0px;
  height: 50px;
  width: calc(100% - 133px);
  border-top-right-radius: 70px;
  border-bottom-right-radius: 70px;
  font-size: 15px;
  letter-spacing: 0.4px;
  outline: none;
  @media screen and (max-width: 450px) {
    font-size: 14px;
    width: calc(100% - 115px);
    padding-right: 25px;
  }
}
/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.search_byo_btn {
  position: absolute;
  right: 0px;
  padding: 0 15px;
  height: 50px;
  border: 0px;
  background-color: #e0e0e0;
  border-top-right-radius: 70px;
  border-bottom-right-radius: 70px;
  outline: none;
  transition: all ease 0.1s;
  @media screen and (max-width: 450px) {
    background-color: transparent;
    &:hover{
      background-color: transparent;
    }
  }
  &:hover {
    background-color: #d6d6d6;
  }
  &:focus {
    outline: none;
  }
  i {
    vertical-align: middle;
    color: rgb(197, 157, 72);
    color: #3c4167;
  }
  img {
    height: 28px;
  }
}

// header_float

header.scrolling {
  transition: all ease 0s;
  height: 90px;
  position: sticky;
  top: 0px;
  z-index: 9999;
  .container {
    display: flex;
  }
  .site_logo {
    height: 50px;
    width: 153px;
    top: 0px;
    margin: 0px;
    left: 0px;
    @media screen and (max-width:768px) {
      display: none;
    }
  }
  .byo_name_input,
  .zip_input,
  .search_byo_btn {
    height: 45px;
  }
}

// Shrink header styles

.shrink {
  .site_logo {
    top: 0px;
    width: 230px;
    margin-bottom: 0px;
    @media screen and (max-width: 768px) {
      width: 150px;
    }
  }
  header {
    height: 150px;
    transition: all ease 0.5s;
    .container{
      display: block;
    }
    @media screen and (max-width: 768px) {
      height: 90px;
      position: sticky;
      top: 0px;
    }
    @media screen and (min-width:768px) {
      
    }
    &.scrolling{
      height: 90px;
      @media screen and (max-width:768px) {
        height: 90px;
        .container{
          display: block;
        }
      }
      .site_logo{
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .search_container {
    display: none;
  }
}
