body {
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
}
.byo_group {
  margin: 20px 0px;
  @media screen and (max-width: 768px) {
    margin: 15px 0px;
  }
  label {
    display: block;
    width: 100%;
    margin-bottom: 7px;
    color: #575757;
    font-weight: 700;
  }
  input {
    display: block;
    width: 100%;
    border: 1px solid #d7be7f;
    background-color: #fffdf6;
    outline: none;
    padding: 10px 10px;
    // font-family: "metric", sans-serif;
    &:read-only {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  select {
    display: block;
    width: 100% !important;
    border: 1px solid #d7be7f;
    background-color: #fffdf6;
    outline: none;
    padding: 10px 10px;
    // font-family: "metric", sans-serif;
  }
  textarea {
    display: block;
    width: 100% !important;
    border: 1px solid #d7be7f;
    background-color: #fffdf6;
    outline: none;
    padding: 10px 10px;
    // font-family: "metric", sans-serif;
  }
}

.custom_toggle {
  input[type="checkbox"] {
    display: none;
    &:checked ~ .make_toggle {
      background-color: #494f7b;
      span {
        left: 32px;
      }
    }
  }
  label {
    display: inline-block !important;
    width: auto;
    vertical-align: middle;
  }
  .make_toggle {
    display: inline-block;
    background-color: #dbdbdb;
    width: 50px !important;
    height: 20px;
    border-radius: 25px;
    position: relative;
    margin-left: 15px;
    cursor: pointer;
    span {
      position: absolute;
      background-color: #fff;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      top: 2px;
      left: 2px;
      cursor: pointer;
      transition: all ease 0.3s;
    }
  }
}

label + .single_service_day {
  margin-top: 20px;
}
.single_service_day {
  // font-family: "metric", sans-serif;
  margin-bottom: 17px;
  @media screen and (max-width: 420px) {
    position: relative;
    //   padding-bottom: 80px;
  }
  h4 {
    font-weight: 500;
    font-size: 16px;
    color: #575757;
  }
  input[type="checkbox"] {
    display: none;
  }
  .make_toggle {
    display: inline-block;
    background-color: #dbdbdb;
    width: 50px !important;
    height: 20px;
    border-radius: 25px;
    position: relative;
    margin-left: 15px;
    margin-bottom: 0px;
    cursor: pointer;
    vertical-align: middle;
    span {
      position: absolute;
      background-color: #fff;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      top: 2px;
      left: 2px;
      cursor: pointer;
      transition: all ease 0.3s;
    }
  }
}
.timing_inputs {
  width: 340px;
  margin-left: 40px;
  @media screen and (max-width: 768px) {
    display: block !important;
    margin-left: 0px;
    margin-top: 17px;
    margin-bottom: 45px;
    width: 100%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
  @media screen and (max-width: 430px) {
    margin-left: 0px;
    display: block;
    margin-top: 17px;
    margin-bottom: 40px;
  }
  span {
    display: inline-block;
    margin: 0px 6px;
    font-size: 15px;
  }
  input {
    display: inline-block !important;
    width: 105px;
    padding: 1px 5px;
    text-align: center;
    border-radius: 2px;
    font-size: 14px;
  }
}

input[type="checkbox"] ~ .timing_inputs {
  display: none;
}
.rc-time-picker-clear {
  display: none;
}
input[type="checkbox"]:checked ~ .timing_inputs {
  display: inline-block;
  @media screen and (max-width: 500px) {
    display: block;
  }
}
input[type="checkbox"]:checked {
  & + .make_toggle {
    background-color: #494f7b;
    span {
      left: 32px;
    }
  }
}

.day_name {
  display: inline-block;
  width: 93px;
  font-weight: 700;
  // color: #000;
}
.timings_hint {
  color: rgb(141, 141, 141);
  margin-bottom: 25px;
}
// Custom react select
.customized__control--is-focused {
  box-shadow: none !important;
  z-index: 5;
}
.customized__control {
  border: 1px solid #d7be7f !important;
  background-color: #fffdf6 !important;
  // font-family: "metric", sans-serif;
  font-weight: 600;
  border-radius: 2px !important;
  min-height: 46px !important;
}
.customized__multi-value {
  border-radius: 50px !important;
  padding: 0px 7px;
  background-color: #d7be7f !important;
}

.customized__multi-value__remove {
  background-color: #c0a35b !important;
  border-radius: 35px !important;
  width: 20px;
  height: 20px;
  margin-top: 2.5px;
  cursor: pointer;
}
.customized__menu {
  margin-top: -5px !important;
  z-index: 2;
  left: 1px;
  width: calc(100% - 4px) !important;
  // font-family: "metric", sans-serif;
}
.customized__menu-list {
  padding-bottom: 0px !important;
  cursor: pointer;
}
.customized__option--is-focused {
  background-color: #d7be7f !important;
}
.detail_error {
  text-align: center;
  color: red;
}

.byo-main-detail {
  text-align: center;
  h1 {
    margin: 0px;
  }
  p {
    color: #575757;
    white-space: pre-wrap;
  }
}

.byo-logo {
  width: 90%;
  left: 0;
  right: 0;
  img {
    width: 90%;
    left: auto;
    right: auto;
    display: block;
    margin: 20px;
  }
}
.image_container {
  margin-top: 30px;
}
.each_image {
  display: inline-block;
  position: relative;
  margin: 5px;
}

.each_image img {
  height: 120px;
}

.remove_images {
  position: absolute;
  background-color: transparent;
  border: 0px;
  top: 4px;
  right: 2px;
  height: 24px;
  margin: 0;
  padding: 0;
}

.remove_images i {
  color: #fff;
  font-size: 22px;
}

.all_types_of_photos {
  justify-content: center;
}

.photo_cards p {
  margin-bottom: 5px;
  line-height: normal;
  margin-top: 3px;
}

.cropBody {
  max-width: 600px;
  background-color: #fff;
  /* padding: 0px 30px 30px; */
  border-radius: 13px;
  text-align: center;
  position: relative;
  min-width: 400px;
}

.cropBody h1 {
  font-size: 25px;
  padding-top: 15px;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 10px;
}

.modal_body h1 {
  font-size: 25px;
  padding-top: 15px;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 10px;
}

.modal_body h1 > span,
.cropBody h1 > span {
  position: absolute;
  right: 18px;
  cursor: pointer;
  color: #a8a8a8;
}

.cropBody i:hover {
  color: #000;
  transition: all ease 0.2s;
}

.paddingCrop {
  text-align: right;
  button {
    // font-family: "metric", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 19px;
    font-weight: 400;
    background-color: #ff9800;
    border: 0;
    border-radius: 3px;
    color: #fff;
    transition: all ease 0.2s;
    padding: 5px 25px 6px;
    min-width: 150px;
    cursor: pointer;
    transition: all ease 0.2s;
    &:hover {
      background-color: #e48900;
    }
  }
}

.cuisine_image_update {
}

.half_footer {
  border-top: 0px;
  padding-left: 6px;
  display: inline-block;
  width: calc(100% - 160px);
  padding-right: 0;
}

.half_footer .custom-control {
  display: inline-block;
  margin-top: 8px;
}

.ReactCrop {
  background-color: transparent !important;
}

.remove_hours {
  font-size: 23px;
  margin-left: 3px;
  color: #afafaf;
  cursor: pointer;
  transition: all ease 0.2s;
}

.byo_details_table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 15px;
  td {
    border: 1px solid #f1f1f1;
    text-align: left;
    padding: 10px 25px;
    @media screen and (max-width: 768px) {
      display: block;
    }
    b {
      display: block;
    }
    &:first-child {
      border-left: 0px;
    }
    &:last-child {
      border-right: 0px;
    }
  }
  tr:last-child {
    td {
      border-bottom: 0px;
    }
  }
  .value_fields {
    // font-family: "metric", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 3px;
    color: #696969;
    a {
      color: #aa8f4c;
    }
  }
  ul {
    display: block;
    list-style: none;
    padding: 0px;
    margin: 0px;
    margin-top: 10px;
    li {
      border: 1px solid #d4d4d4;
      color: #696969;
      background-color: #e6e6e6;
      display: inline-block;
      padding: 4px 15px;
      border-radius: 50px;
      margin: 2px;
      font-size: 14px;
      min-width: 275px;
      &:first-child {
        margin-left: 0px;
      }

      &.image {
        position: relative;
        min-width: auto;
        padding: 3px 15px 3px 35px;
      }
      &.r_size {
        position: relative;
        min-width: auto;
      }
      img {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        position: absolute;
        left: 3px;
        top: 1px;
      }
    }
  }
}

.fit_image_crop {
  .slick-slider,
  .slick-track {
    height: 545px;
  }
  height: 545px;
  overflow: hidden;
  background-color: #424242;
  @media screen and (max-width: 1200px) {
    .slick-slider,
    .slick-track {
      height: 523.23px;
    }
    height: 523.23px;
  }
  @media screen and (max-width: 991px) {
    .slick-slider,
    .slick-track {
      height: 388.13px;
    }
    height: 388.13px;
  }
  @media screen and (max-width: 768px) {
    .slick-slider,
    .slick-track {
      height: 410px;
    }
    height: 410px;
  }
  @media screen and (max-width: 767px) {
    .slick-slider,
    .slick-track {
      height: 303.75px;
    }
    height: 303.75px;
  }
  @media screen and (max-width: 500px) {
    .slick-slider,
    .slick-track {
      height: 275px;
    }
    height: auto;
  }
  @media screen and (max-width: 425px) {
    .slick-slider,
    .slick-track {
      height: 240px;
    }
    height: 240px;
  }
  @media screen and (max-width: 375px) {
    .slick-slider,
    .slick-track {
      height: 210px;
    }
    height: 210px;
  }
  @media screen and (max-width: 320px) {
    .slick-slider,
    .slick-track {
      height: 180px;
    }
    height: 180px;
  }
  // @media screen and (max-width: 991px) {
  //   height: auto;
  // }
  // @media screen and (max-width: 768px) {
  //   height: auto;
  // }
  img {
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-slide,
  .slick-slide > div,
  .slick-slide > div > div {
    // display: flex !important;
    height: 100%;
    // align-items: center;
    // justify-content: center;
  }
  .slick-slide > div > div {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .slick-prev {
    left: 0px;
    z-index: 99;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.158) 45%, rgba(0, 0, 0, 0.007));
    &:hover {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.158) 45%, rgba(0, 0, 0, 0.007));
    }
  }
  .slick-next {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.198) 45%, rgba(0, 0, 0, 0.007));
    right: 0px;
    z-index: 99;
    &:hover {
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.198) 45%, rgba(0, 0, 0, 0.007));
    }
  }
  .slick-prev,
  .slick-next {
    height: 100%;
    width: 75px;
    &::before {
      opacity: 1;
      font-size: 35px;
    }
  }
}
.description_byo_det {
  text-align: left;
  padding: 25px 25px 15px;
  p {
    // font-family: "metric", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
}
.byo-detail {
  background-color: #f5f5f5;
  padding: 0px 0px 30px 0px;
  @media screen and (max-width: 768px) {
    padding: 0px 0px;
    margin-bottom: 45px;
    .container {
      width: initial;
      padding: 0px;
    }
    &+footer{
      padding-bottom: 75px;
    }
  }
  .full_byo_container {
    background-color: #fff;
    padding-bottom: 10px;
  }
}

.more_images_view {
  padding: 0px;
  margin: 0px;
  height: 45px;
  display: inline-block;
  position: relative;
  top: 7px;
  border: 0px;
  background-color: #fff;
  border-radius: 2px;
  i {
    font-size: 30px;
    padding: 0px;
    margin: 0px;
    position: relative;
    top: 3px;
  }
}

.full_det_header {
  position: relative;
  padding: 25px;
  text-align: left;
  padding-left: 120px;
  @media screen and (max-width: 768px) {
    padding-left: 80px;
    padding-top: 36px;
    padding-bottom: 36px;
  }
  h1 {
    margin: 0px;
    line-height: 70px;
    padding-right: 160px;
    @media screen and (max-width: 768px) {
      line-height: normal;
      font-size: 22px;
      padding-right: 0px;
    }
  }
  img {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    position: absolute;
    left: 25px;
    @media screen and (max-width: 768px) {
      height: 55px;
      width: 55px;
      left: 15px;
      top: 25px;
    }
  }
  button {
    // font-family: "metric", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    position: absolute;
    right: 25px;
    top: calc(50% - 20px);
    // background-color: #eee;
    border: 0px;
    padding: 8px 25px;
    border-radius: 35px;
    color: #fff;
    transition: all ease 0.2s;
    @media screen and (max-width: 768px) {
      position: fixed;
      text-align: center;
      font-size: 22px;
      height: 55px;
      top: unset;
      bottom: 0px;
      border-radius: 0px;
      width: 100%;
      left: 0px;
      z-index: 999;
    }
    &.edit {
      background-color: #494f7b;
      &:hover {
        background-color: #363c64;
      }
    }
    &.claim_now {
      background-color: #494f7b;
      &:hover {
        background-color: #494f7b;
      }
    }
    i {
      padding: 0px;
      margin: 0px;
      font-size: 15px;
      vertical-align: middle;
      margin-right: 7px;
      @media screen and (max-width: 768px) {
        font-size: 19px;
      }
    }
  }
}
.other_byo_images {
  margin-top: -10px;
  padding: 7.5px;
  box-sizing: border-box;
  background-color: #fff8e6;
  z-index: 2;
  position: relative;
  text-align: justify;
  @media screen and (max-width: 768px) {
    margin: 0px;
    background-color: #fff;
    text-align: center;
  }
  a {
    display: inline-block;
    padding: 5px;
    img {
      height: 45px;
      // border: 1px solid #505050;
      max-width: 100%;
      @media screen and (max-width: 768px) {
        height: 57px;
      }
    }
  }
}

.other_byo_images {
  .slick-slider {
    padding: 0px 45px;
    @media screen and (max-width: 768px) {
      padding: 0px 38px;
    }
  }
  .slick-prev {
    left: 10px;
    width: 27px;
    height: 27px;
    @media screen and (max-width: 768px) {
      left: 2px;
      &:before {
        color: #aa8f4c;
      }
    }
    &:before {
      font-size: 27px;
      color: #1c1488;
    }
  }
  .slick-next {
    right: 10px;
    width: 27px;
    height: 27px;
    @media screen and (max-width: 768px) {
      right: 2px;
      &:before {
        color: #aa8f4c;
      }
    }
    &:before {
      font-size: 27px;
      color: #1c1488;
    }
  }
}

.image_container {
  .each_image {
    background-color: #595959;
    position: relative;
    img {
      min-width: 50px;
    }
    &:before {
      content: "";
      background-image: linear-gradient(45deg, rgba(0, 0, 0, 0), rgb(0, 0, 0) 115%);
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      position: absolute;
    }
  }
}
.view_m_images {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  border: 1px solid #d6d9f1;
  padding: 5px 15px 7px;
  color: #6c77d0;
  border-radius: 4px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: #eaecfb;
  display: block;
}
.SingleDatePicker,
.SingleDatePickerInput {
  display: block;
  border: 0px;
  .DateInput {
    width: 100%;
  }
}

.byo_timing {
  width: calc(30% - 2px);
  @media screen and (max-width: 990px) {
    width: 100% !important;
    display: block !important;
    margin-bottom: 5px !important;
    margin-left: 0px !important;
    max-width: 255px;
  }
}
.view_more_imgs {
  // font-family: "metric", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #363c64;
  color: #fff;
  background-origin: 0px;
  border: 0px;
  border-radius: 3px;
  display: inline-block;
  padding: 3px 10px;
  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
  }
}
.hint_country {
  font-size: 16px;
  margin-left: 5px;
  position: relative;
  top: 2px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.country_p_hint {
  display: none;
  @media screen and (max-width: 768px) {
    // font-family: "metric", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    display: block;
    color: #afafaf;
    line-height: normal;
    font-size: 13px;
    padding-top: 5px;
  }
}

.single_dic_select {
  // border: 1px solid #000;
  // font-family: "metric", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-transform: capitalize;
  padding: 5px 15px;
  border-radius: 50px;
  background-color: #363c64;
  color: #fff;
  display: inline-block;
  margin-top: 10px;
  i {
    vertical-align: middle;
    margin-left: 10px;
    cursor: pointer;
  }
}

.not_found_page {
  text-align: center;
  min-height: calc(100vh - 300px);
  padding: 75px 0px;
  img {
    height: 75px;
    margin-bottom: 20px;
  }
}

.save_time {
  background-color: #494f7b;
  border: 0px;
  border-radius: 60px;
  color: #fff;
  font-size: 13px;
  padding: 4px;
  font-weight: 700;
  width: 70px;
  margin-left: 7px;
  position: relative;
  top: -2px;
  @media screen and (max-width: 375px) {
    display: block;
    margin-top: 12px;
    margin-left: 0px !important;
  }
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25 !important;
}
.minor_hint {
  font-size: 14px;
  padding-top: 4px;
  color: #636363;
}
.CalendarMonth_caption {
  padding-top: 18px;
  select{
    border: 1px solid #e4e7e7;
    font-size: 15px;
    margin: 0 3px;
    height: 32px;
  }
}