.user_profile_page {
  min-height: 400px;
}
.profile_head {
  background-color: #fff9e9;
  position: sticky;
  top: 90px;
  z-index: 99;
}
.tab {
  display: inline-block;
  padding: 19px 30px;
  width: 240px;
  color: #575757;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  @media screen and (max-width: 991px) {
    padding: 30px 10px;
    width: 180px;
    font-size: 17px;
  }
  @media screen and (max-width: 768px) {
    padding: 15px 5px;
    width: 50%;
    font-size: 18px;
  }
  &:hover {
    background-color: rgb(253, 245, 223);
  }
  &.active {
    background-color: #fff;
    color: #3c4167;
  }
}
.logout_link_profile {
  color: #c3982a;
  background-color: transparent;
  padding: 0px;
  border: 0px;
  // font-family: "metric", sans-serif;
  font-size: 21px;
  font-weight: 500;
  float: right;
  margin-top: 17px;
  &:hover {
    color: #cfb163;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.logout_link_mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    width: 100%;
    background-color: #c3982a;
    // font-family: "metric", sans-serif;
    font-weight: 500;
    border: 0px;
    color: #fff;
    padding: 15px 0px;
    font-size: 25px;
    margin-top: 65px;
  }
}

.claim_trigger_yellow {
  display: inline-block;
  border: 1px solid #eee;
  width: 150px;
  text-align: center;
  padding: 10px 0px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.2px;
  border-radius: 90px;
  background-color: #3c4167;
  outline: none;
  width: 117px;
  text-align: center;
  padding: 6px 0px;
  img {
    height: 17px;
    margin-right: 7px;
  }
  &:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: #d4b66a;
    color: #fff;
  }
}
.claim_trigger_black {
  display: inline-block;
  border: 1px solid #eee;
  width: 150px;
  text-align: center;
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.2px;
  border-radius: 90px;
  background-color: #575757;
  outline: none;
  &:hover {
    cursor: default;
  }
}
.user_profile_table {
  max-width: 500px;
  h3 {
    font-family: "mercury";
    font-size: 22px;
  }
  .profile_value {
    color: #747474;
    font-size: 18px;
  }
}
.profile_buttons {
  margin-bottom: 90px;
  @media screen and (max-width: 768px) {
    padding-left: 20px;
    .profile_secondary {
      margin-left: 5px;
      min-width: 80px !important;
      width: 100px !important;
    }
  }
}

.user_profile_table {
  margin-bottom: 25px;
  .custom_toggle {
    margin-left: 0px !important;
  }
  tr {
    margin: 0px !important;
    box-shadow: none !important;
  }
  td:last-child {
    text-align: left !important;
  }
  tr:last-child {
    td {
      border: 0px;
    }
  }
}
.profile_primary {
  background-color: #cfb163;
  color: #fff;
  // font-family: "metric", sans-serif;
  border-radius: 50px;
  border: 0px;
  padding: 9px 15px;
  min-width: 150px;
  font-size: 14px;
}
.profile_secondary {
  color: #fff;
  // font-family: "metric", sans-serif;
  background-color: #3c4167;
  border-radius: 50px;
  border: 0px;
  padding: 9px 15px;
  margin-left: 20px;
  font-size: 14px;
  min-width: 150px;
}
.profile_tabs {
  a {
    border-left: 1px solid #ffeec1;
    text-decoration: none;
    font-weight: 700;
  }
}

.inner_back {
  color: #c3982a;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0px;
  letter-spacing: 0.4px;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  padding-left: 45px;
  padding-top: 25px;
  position: relative;
  img {
    height: 32px;
    vertical-align: top;
    margin-right: 12px;
    margin-left: -7px;
    left: 0px;
    cursor: pointer;
    position: absolute;
    bottom: 19px;
  }
  @media screen and (max-width: 575px) {
    padding-left: 60px;
    img {
      left: 15px;
    }
  }
}
.byo_requests {
  float: right;
  font-size: 16px;
  position: relative;
  top: 9px;
  color: #3c4167;
  font-weight: 700;
  text-decoration: underline;
  @media screen and (max-width: 550px) {
    float: none;
    display: block;
  }
}
.terms_p {
  // font-family: "metric", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 19px;
  text-align: left;
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -10px;
  a {
    color: #cfb163;
    display: inline-block;
  }
}

.profile_tabs {
  @media screen and (max-width: 768px) {
    overflow-x: auto;
  }
}
.inner_tabs_scroll {
  display: inline-block;
  @media screen and (max-width: 768px) {
    display: inline;
    white-space: pre;
    .tab{
      padding: 20px 15px;
      width: 220px;
    }
  }
}
