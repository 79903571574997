.search_results {
  padding: 45px 0px 0px 0px;
  h3 {
    color: #575757;
    // font-family: "metric", sans-serif;
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 25px;
    @media screen and (max-width:768px) {
      margin-bottom: 25px;
    }
    span {
      display: block;
      font-weight: 400;
      font-size: 17px;
      color: #b4b4b3;
      margin-left: 0px;
    }
  }
}
.each_result_cont {
  table {
    width: 100%;
    color: #3c4167;
    // font-family: "metric", sans-serif;
    tr {
      @media screen and (max-width: 768px) {
        display: block;
        width: 100%;
        box-shadow: 0 2px 18px 0 rgba(0, 4, 63, 0.1);
        padding: 5px 20px;
        margin-bottom: 20px;
      }
    }
    td {
      // padding: 25px 0px;
      // border-bottom: 1px solid #fdf7e7;
      padding: 30px 15px;
      border-bottom: 1px solid #f3f3f3;
      vertical-align: middle;
      @media screen and (max-width: 768px) {
        border-bottom: 1px solid #f3f3f3;
        padding: 15px 0px;
        display: block;
        width: 100%;
      }
    }
    td:last-child {
      text-align: right;
      @media screen and (max-width: 768px) {
        text-align: left;
        border-bottom: 0px;
      }
    }
  }
  thead{
    color: #9d9d9d;
    @media screen and (max-width:768px) {
      display: none;
    }
  }
  .byo_name {
    font-size: 22px;
    margin: 0px;
    line-height: normal;
    color: #4d58ad;
    position: relative;
    cursor: pointer;
    // transition: all ease 0s;
    &.disabled_link{
      color: #4b4b4b;
      cursor: default;
      &:hover{
        color: #4b4b4b;
      }
    }
    &.verified:after {
      content: "";
      width: 20px;
      height: 20px;
      display: inline-block;
      background-image: url(/images/check.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      left: 4px;
      position: relative;
      top: 3px;
    }
    &:hover{
      color: #3c4167;
    }
  }
  .byo_cuisine {
    color: #575757;
  }
  .byo_number {
    font-weight: 600;
  }
  .clain_trigger {
    display: inline-block;
    border: 1px solid #eee;
    width: 150px;
    text-align: center;
    padding: 7px 0px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.2px;
    border-radius: 90px;
    background-color: #3c4167;
    outline: none;
    transition: all ease 0.3s;
    &:hover {
      background-color: #c3982a;
      text-decoration: none;
    }
  }
}

.see_more {
  text-align: center;
}
.no_byo_found {
  padding: 50px 15px;
  margin-top: -35px;
  background-color: #eee;
  text-align: center;
  // font-family: "metric", sans-serif;
  font-weight: 500;
  color: rgb(87, 87, 87);
  img {
    width: 40px;
    margin-right: 20px;
    @media screen and (max-width: 768px) {
      display: block;
      margin: 0 auto;
      margin-bottom: 25px;
    }
  }
}
.claimed_button{
  width: 150px;
  text-align: center;
  padding: 7px 0px;
  font-size: 17px;
  font-weight: 500;
  color: #707070;
  letter-spacing: 0.2px;
  border-radius: 90px;
  border: 0px;
  background-color: #fff;
  border: 1px solid #707070;
}
.display_count_results{
  // font-family: "metric", sans-serif;
  text-transform: capitalize;
  padding: 10px 0px;
  text-align: right;
  font-size: 19px;
  color: rgb(102, 101, 101);
  @media screen and (max-width:768px) {
    text-align: center;
    margin-top: 0px;
  }
  span{
    font-weight: 600;
    padding: 0 5px;
  }
}
.see_more_results{
  color: #c3982a;
  // border: 1px solid #d7be7f;
  // font-family: "metric", sans-serif;
  font-weight: 600;
  font-size: 25px;
  display: inline-block;
  padding: 4px 15px;
  width: 190px;
  border-radius: 25px;
  margin-bottom: 100px;
  cursor: pointer;
  transition: all ease 0.2s;
  &:hover{
    color: #c3aa69;
  }
  img{
    height: 20px;
    margin-left: 8px;
  }
}